<template>

  <div class="footer">

    <div class="tops">

      <div class="zx">
        <div>
          <div class="tr">咨询热线</div>
          <div class="tr">13666168545</div>
<!--          <div class="tr">-->
<!--            薛先生 18402841794（微信同）-->
<!--            李先生 18980418191（微信同）-->
<!--          </div>-->
        </div>

        <div>
          <div class="tr">公司地址</div>
          <div class="tr">四川省成都市青羊区新时代广场22楼</div>
        </div>


        <div class="img">
          <img src="../assets/gzh.png" alt="">
          <div class="g">
            <img src="../assets/home/wx.png" alt="">
            <img src="../assets/home/wb.png" alt="">
            <img src="../assets/home/zf.png" alt="">
          </div>
        </div>

      </div>

      <div class="cp">
        <div class="ti">
          产品
        </div>
        <router-link v-for="(v,i) in d.product" :to="`/product?id=${v.id}`" class="t" :key="i">{{
            v.title
          }}
        </router-link>
      </div>

      <div class="jj">

        <div class="ti">
          解决方案
        </div>
        <router-link v-for="(v,i) in d.solution" :to="`/solution?id=${v.id}`" class="t" :key="i">{{
            v.title
          }}行业
        </router-link>
      </div>

      <div class="gy">

        <div class="ti">
          关于我们
        </div>
        <router-link to="/we" class="t">企业介绍</router-link>
        <router-link to="/we" class="t">联系我们</router-link>
      </div>

      <div class="xw">

        <div class="ti">
          新闻咨询
        </div>

        <router-link to="/news?type=1" class="t">企业动态</router-link>
        <router-link to="/news?type=2" class="t">行业咨询</router-link>
      </div>

    </div>


    <div class="recordInformation">
      <span>米果码专业的溯源系统集成服务商   Copyright © 2016-2021 www.huxiawang.com 米果码 版权所有</span>
      <div></div>
      <a style="color: #ffffff" href="https://beian.miit.gov.cn/">蜀ICP备16011315号-1</a>

    </div>

  </div>

</template>


<script>
import { Data } from "@/utils/ decorator";

export default {
  name: "footers",
  props: {},
  data() {
    return {
      d: {}
    }
  },
  @Data( '' )
  created() {

  },
  methods: {
    go( path ) {
      this.$router.push( { path } );
      scrollTo( {
        top: 0,
        behavior: 'smooth'
      } );
    }
  }
}
</script>

<style lang="less" scoped>


.footer {
  width: 100%;
  background-color: #202020;


  .tops {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 100px 110px 0;
    color: #FFFFFF;
  }


  .recordInformation {
    border-top: 1px solid;
    padding: 32px 0;
    margin: 20px auto 0;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #FFFFFF;
    width: 1340px;

    div {
      width: 250px;
      display: inline-block;
    }

  }


  .cp, .jj, .gy, .xw {
    text-align: center;
  }


  .zx {

    .tr {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 16px;
    }


    .img {
      img {
        width: 64px;
        height: 64px;
      }

      .g {
        margin-top: 22px;
        display: flex;

        img {
          width: 34px;
          height: 34px;
          margin-right: 14px;
        }
      }

    }


  }


  .ti {
    margin-bottom: 22px;
    font-size: 16px;
    font-weight: 300;
    color: #FFFFFF;
  }

  .t {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #FFFFFF;
    margin-bottom: 16px;
    cursor: pointer;
    display: block;
  }


}


</style>
